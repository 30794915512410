
import React from "react"
import Card from "../components/card";

export default class Recommendations extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <div className="o-content-container">
                <h1 className="u-text-center">Recommendations</h1>
                <div class="o-flex o-flex--quarter o-flex--align-start">
                    {this.props.recommendations.map((card, index) =>
                        <Card key={index} card={card} cta={this.props.videoCtaText}></Card>
                    )}
                </div>
            </div>
        );
    }
};