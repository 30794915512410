import React from "react"
import Seo from "../components/seo"
import "../styles/theme.scss"
import Layout from "../layouts/layout";
import { graphql } from "gatsby"
import Videos from "../components/videos";
import Recommendations from "../components/recommendations";

export default class Article extends React.Component {
    render() {
        let global = this.props.data.contentfulWebsite;
        return (
            <Layout pathname={this.props.location.pathname}>
                <Seo metaTitle={`${this.props.pageContext.series} - ${global.title}`} metaDescription={global.description} author={global.author} shareImage={global.shareImage.file.url} />
                <div className="o-wrapper o-content o-container">
                    <div className="o-wrapper o-wrapper--small">
                        {this.props.pageContext.episodes.length > 0 ? <Videos episodes={this.props.pageContext.episodes}></Videos> : ''}
                    </div>
                    <div className="o-content-spacer">
                        <Recommendations recommendations={this.props.pageContext.recommendations} videoCtaText={global.videoCtaText}></Recommendations>
                    </div>
                </div>
            </Layout>
        );
    };
}

export const pageQuery = graphql`
{
    contentfulWebsite(title: {eq: "UNINTERRUPTED Canada"}) {
        author
        description
        title
        videoCtaText
        shareImage {
            file {
                url
            }
        }
    }
}
`;
